<template>
  <div>
    <Header />
 <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="tabletopdigi()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/inspection_testing/microscope/tabletop.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Tabletop Digi Professional Microscopes </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="portabledigital()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 60%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/inspection_testing/microscope/portable.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Portable Digital Microscope With Lcd Screen   </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="usbdigital()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 60%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/inspection_testing/microscope/digital.gif"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">USB Digital Microscope</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="baroscop()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 60%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/inspection_testing/microscope/barescop.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Baroscope</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../components/Header.vue";

import Footer from "../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
     tabletopdigi(){
      window.location.href = "http://www.vitiny-usa.com/digi-tabletop-microscopes.html";

    },
    portabledigital(){
      window.location.href = "http://www.vitiny-usa.com/portable-digital-microscopes-with-lcd-screen.html";

    },
    usbdigital(){
      window.location.href = "http://www.vitiny-usa.com/usb-digital-microscopes1.html";

    },
    baroscop(){
      window.location.href = "http://www.vitiny-usa.com/vt400-videoscope.html";
    
    },
  },
};
</script>